import { type MetaDescriptor } from "@remix-run/node";

interface Parameters {
  title?: string | null;
  description?: string | null;
  image?: string | null;
  twitterCard?: string | null;
  noIndex?: boolean | null;
}

/**
 * Creates a meta array for Remix.
 * With the common meta tags for SEO to be used on all pages.
 */
export const getPageMeta = ({
  title,
  description,
  image,
  twitterCard,
  noIndex,
}: Parameters) => {
  const meta: MetaDescriptor[] = [];

  if (title) {
    meta.push(
      {
        title,
      },
      {
        property: "og:title",
        content: title,
      },
      {
        property: "og:site_name",
        content: title,
      },
      {
        name: "twitter:title",
        content: title,
      }
    );
  }

  if (description) {
    meta.push(
      {
        name: "description",
        content: description,
      },
      {
        property: "og:description",
        content: description,
      },
      {
        name: "twitter:description",
        content: description,
      }
    );
  }

  if (image) {
    meta.push(
      {
        property: "og:image",
        content: image,
      },
      {
        name: "twitter:image",
        content: image,
      }
    );
  }

  if (twitterCard) {
    meta.push({
      name: "twitter:card",
      content: twitterCard,
    });
  }

  if (noIndex) {
    meta.push({
      name: "robots",
      content: "noindex",
    });
  }

  return meta;
};
