/**
 * A helper function to remove a locale from a path if it exists.
 * Always returns a path with a leading and trailing slash.
 *
 * @param path the path to remove the locale from
 * @param locale the locale to remove from the path
 * @returns `/${pathWithoutLocale}/`
 */
const removeLocaleFromPath = (path: string, locale: string) => {
  const splitPath = path.split("/").filter(Boolean);

  if (splitPath[0] === locale) {
    splitPath.splice(0, 1);
  }

  return `/${splitPath.join("/")}/`;
};

export default removeLocaleFromPath;
