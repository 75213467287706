import styled from "@emotion/styled";
import {
  Caption,
  CaptionVariantSizes,
  Colors,
  ConvertHTMLtoComponents,
  Grid,
  GridColumn,
  Section,
  Sizes,
} from "@polestar/component-warehouse-react";

interface Props {
  readonly text: string;
}

const StyledCaption = styled(Caption)`
  display: block;
  margin-bottom: ${CaptionVariantSizes.small.lineHeight};
  color: ${Colors.stormGrey};
`;

const Disclaimer = ({ text }: Props) => {
  return (
    <Section data-testid="disclaimer">
      <Grid>
        <GridColumn
          span={{ mobile: 4, tablet: 5, desktop: 8, desktopWide: 6 }}
          offset={{
            mobile: 0,
            tablet: 3,
            desktop: 4,
            desktopWide: 4,
          }}
        >
          <ConvertHTMLtoComponents
            overrideElementMap={{
              p({ children }) {
                return (
                  <StyledCaption as="p" size={Sizes.small}>
                    {children}
                  </StyledCaption>
                );
              },
            }}
          >
            {text}
          </ConvertHTMLtoComponents>
        </GridColumn>
      </Grid>
    </Section>
  );
};

export default Disclaimer;
