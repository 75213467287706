import styled from "@emotion/styled";
import {
  Caption,
  Colors,
  ConvertHTMLtoComponents,
  Opacities,
  Sizes,
  Spacings,
  createCustomSpacing,
  withOpacity,
} from "@polestar/component-warehouse-react";

interface Props {
  readonly orderedList?: Array<{
    id: string;
    text: string;
  }>;
  readonly unorderedList?: Array<{
    id: string;
    text: string;
  }>;
}

const StyledCaption = styled(Caption)`
  display: list-item;
  color: ${withOpacity(Colors.black, Opacities.medium)};
  margin-bottom: ${Spacings.small};
`;

const StyledOrderedList = styled.ol`
  margin-left: ${createCustomSpacing(1.25)};
  list-style: decimal;
`;

export const parseListItems = (
  items?: Array<{
    id: string;
    text: string;
  }>
) => {
  if (!items?.length) {
    return null;
  }

  return items
    ?.map(({ text }) => {
      return text;
    })
    .join("")
    .replaceAll("<span>", "")
    .replaceAll("</span>", "");
};

const DisclaimerList = ({ orderedList, unorderedList }: Props) => {
  const parsedOrderedListItem = parseListItems(orderedList);
  const parsedUnorderedListItem = parseListItems(unorderedList);
  const html = [
    parsedOrderedListItem ? `<ol>${parsedOrderedListItem}</ol>` : null,
    parsedUnorderedListItem ? `<ul>${parsedUnorderedListItem}</ul>` : null,
  ].join("");

  if (!html) {
    return null;
  }

  return (
    <ConvertHTMLtoComponents
      data-testid="disclaimer-list"
      overrideElementMap={{
        ol({ children }) {
          return <StyledOrderedList>{children}</StyledOrderedList>;
        },
        ul({ children }) {
          return <ul>{children}</ul>;
        },
        p({ children }) {
          return (
            <StyledCaption as="li" size={Sizes.small}>
              {children}
            </StyledCaption>
          );
        },
      }}
    >
      {html}
    </ConvertHTMLtoComponents>
  );
};

export default DisclaimerList;
