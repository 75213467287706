import styled from "@emotion/styled";
import {
  Colors,
  Grid,
  GridColumn,
  Heading,
  MediaQueries,
  Opacities,
  Section,
  Spacer,
  Spacings,
  withOpacity,
} from "@polestar/component-warehouse-react";
import React, { type FC } from "react";

interface Props {
  readonly title?: string;
  readonly subtitle?: string;
  readonly body?: string;
}

const StyledSubtitle = styled(Heading)`
  color: ${withOpacity(Colors.black, Opacities.medium)};
`;

const StyledGrid = styled(Grid)`
  grid-row-gap: 0;
  ${MediaQueries.tablet} {
    grid-row-gap: 0;
  }
`;

const TextHero: FC<Props> = ({ title, subtitle, body }) => {
  return (
    <Section>
      <StyledGrid>
        <GridColumn span={{ mobile: 4, tablet: 3 }}>
          <StyledSubtitle level={2}>{subtitle}</StyledSubtitle>
          <Heading level={1}>{title}</Heading>
          <Spacer
            spacing={{ mobile: Spacings.medium, tablet: Spacings.none }}
          />
        </GridColumn>
        <GridColumn
          span={{ mobile: 4, tablet: 5, desktop: 6 }}
          offset={{ mobile: 0, tablet: 0, desktop: 1 }}
        >
          <Heading as="p">{body}</Heading>
        </GridColumn>
      </StyledGrid>
    </Section>
  );
};

export default TextHero;
