import styled from "@emotion/styled";
import {
  Breakpoints,
  useMediaQuery,
  VideoPlayer,
} from "@polestar/component-warehouse-react";

import { type ParsedNonAutoplayVideoProps } from "../types/Video";

import getNonAutoplayVideoPosterUrl from "./getNonAutoplayVideoPosterUrl/getNonAutoplayVideoPosterUrl";

const videoContainerProps = {
  style: {
    height: "100%",
    width: "100%",
  },
};

const StyledVideoPlayer = styled(VideoPlayer)`
  object-fit: cover;
`;

const NonAutoplayVideo = ({ video }: ParsedNonAutoplayVideoProps) => {
  const { queryMatches } = useMediaQuery();

  const { sources, poster, subtitles, subtitleLabel, subtitleLanguage } = video;
  const { desktop, tablet, mobile } = sources;

  const posterUrl = getNonAutoplayVideoPosterUrl({ queryMatches, poster });

  return (
    <StyledVideoPlayer
      playsInline
      data-testid="video"
      poster={posterUrl}
      containerProps={videoContainerProps}
      crossOrigin="anonymous"
    >
      <source
        src={desktop.url}
        media={`(min-width: ${Breakpoints.desktop}px)`}
      />
      <source src={tablet.url} media={`(min-width: ${Breakpoints.tablet}px)`} />
      <source src={mobile.url} media={`(min-width: ${Breakpoints.mobile}px)`} />

      {subtitles?.url && (
        <track
          default
          data-testid="subtitle"
          kind="subtitles"
          src={subtitles.url}
          label={subtitleLabel}
          srcLang={subtitleLanguage}
        />
      )}
    </StyledVideoPlayer>
  );
};

export default NonAutoplayVideo;
