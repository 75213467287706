import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";

export interface DatalayerEvent {
  [key: string]: string | Record<string, string> | undefined;
  event?: string;
  eventInfo?: {
    action?: string;
    label?: string;
  };
}

interface Context {
  readonly pushToDatalayer: (
    event?: DatalayerEvent,
    isIframe?: boolean
  ) => void;
}

type Service =
  | "dotcom.about"
  | "dotcom.buying.support"
  | "dotcom.forms"
  | "dotcom.legal"
  | "dotcom.news"
  | "dotcom.offers"
  | "dotcom.products"
  | "dotcom.support";

interface Props {
  readonly service: Service;
  readonly children: ReactNode;
}

const TrackingContext = createContext<Context | null>(null);

export const TrackingProvider = ({ service, children }: Props) => {
  const pushToDatalayer = useCallback(
    (event?: DatalayerEvent, isIframe = false) => {
      if (!event) return;

      const dataLayer = isIframe
        ? (window.parent.dataLayer ||= [])
        : (window.dataLayer ||= []);

      dataLayer.push({ ...event, team: "dotcom", service });
    },
    [service]
  );

  const contextValue = useMemo(() => ({ pushToDatalayer }), [pushToDatalayer]);

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error("useTracking must be used within a TrackingProvider");
  }

  return context;
};
