import { getFullUrl } from "./getFullUrl";

interface Market {
  locale: string;
  languageCode: string;
  countryCode: string;
}

const getHrefLang = (market: Market, isChina: boolean) => {
  if (isChina) {
    return `${market.languageCode}-CN`;
  }

  if (market.locale === "global") {
    return market.languageCode;
  }

  return `${market.languageCode}-${market.countryCode}`;
};

export const getAlternateLinks = (
  pathname: string,
  markets: Market[],
  isChina: boolean
) => {
  const alternateLinks = markets.map((market) => {
    const alternateUrl = getFullUrl(pathname, market.locale, markets, isChina);
    const hrefLang = getHrefLang(market, isChina);

    return {
      tagName: "link",
      rel: "alternate",
      href: alternateUrl,
      hrefLang,
    };
  });

  return alternateLinks;
};
