import { type Market } from "@dotcom/markets";
import {
  Header,
  type HeaderProps,
  NavigationDrawer,
  type NavigationDrawerGroupTypes,
  type HeaderSubNavigationContentProps,
  type MegaMenuProps,
  MegaMenu,
  OfferBar,
  type OfferBarProps,
  ParsedImporterBanner,
  ImporterBannerTop,
} from "@polestar/component-warehouse-react";

import { useDeviceFromUrl } from "../hooks";

import LoginButton from "./LoginButton";
import PreviewBanner from "./PreviewBanner";
import { useTracking } from "../TrackingProvider/TrackingProvider";

interface DrawerItem {
  groupType: NavigationDrawerGroupTypes;
  items: Array<{
    label?: string;
    eventLabel?: string;
    href?: string;
    path?: string;
    groups?: DrawerItem[];
  }>;
}

export interface NavigationProps {
  readonly market?: Market;
  readonly offerBar?: OfferBarProps;
  readonly megaMenu?: MegaMenuProps;
  readonly drawer?: DrawerItem[];
  readonly dictionary?: {
    close?: string;
    back?: string;
    signin?: string;
  };
  readonly previewConfig?: {
    isPreview: boolean;
    previewApiEndpoint: string;
    projectCode?: string | null;
    defaultProjectCode?: string | null;
  };
  readonly header?: {
    variant?: HeaderProps["variant"];
    subNavigation?: HeaderProps["subNavigation"];
  };
  readonly importerBanner?: ParsedImporterBanner;
}

export const getSubNavigation = (
  subNavigation?: HeaderSubNavigationContentProps[],
  previewConfig?: NavigationProps["previewConfig"]
) => {
  const items = [];

  if (subNavigation) {
    items.push(subNavigation);
  }

  if (
    previewConfig &&
    (previewConfig.isPreview ||
      previewConfig.projectCode !== previewConfig?.defaultProjectCode)
  ) {
    items.push({
      id: "preview-banner",
      node: (
        <PreviewBanner
          isPreview={previewConfig.isPreview}
          previewEndpoint={previewConfig.previewApiEndpoint}
          projectCode={previewConfig.projectCode}
          defaultProjectCode={previewConfig.defaultProjectCode}
        />
      ),
      bleed: false,
    });
  }

  if (items.flat().length > 0) {
    return items.flat();
  }

  return undefined;
};

const Navigation = ({
  market,
  offerBar,
  megaMenu,
  drawer,
  dictionary,
  previewConfig,
  header,
  importerBanner,
}: NavigationProps) => {
  const device = useDeviceFromUrl();
  const { pushToDatalayer } = useTracking();

  const handleLoginButtonClick = () => {
    pushToDatalayer({
      event: "link_click",
      category: "menu",
      action: "category_click",
      label: "sign_in",
    });
  };

  // Don't render any navigation on e.g. space tabletops or space tablets
  if (device) {
    return null;
  }

  const hasMarket = market !== undefined;
  const enableLogin = market?.features.includes("enableLogin");

  return (
    <>
      {importerBanner ? (
        <ImporterBannerTop
          data-testid="dotcom-importer-banner-top"
          message={importerBanner.message}
        />
      ) : null}
      {offerBar && (megaMenu || !drawer) ? (
        <OfferBar
          data-testid="dotcom-navigation-offer-bar"
          message={offerBar.message}
          variant={offerBar.variant}
          hasMaxWidth={offerBar.hasMaxWidth}
        />
      ) : null}
      {megaMenu ? (
        <MegaMenu
          data-testid="dotcom-navigation-mega-menu"
          skipToContentId="main-content"
          navigation={megaMenu.navigation}
          divider={megaMenu.divider}
          actions={megaMenu.actions}
          variant={megaMenu.variant}
          ariaLabels={megaMenu.ariaLabels}
          subNavigation={getSubNavigation(
            megaMenu?.subNavigation,
            previewConfig
          )}
          logoProps={{
            href: hasMarket ? `/${market?.locale}/` : "/",
          }}
        />
      ) : null}
      {drawer ? (
        <>
          <Header
            data-testid="dotcom-navigation-header"
            logoProps={{
              as: "a",
              href: hasMarket ? `/${market?.locale}/` : "/",
            }}
            menuButtonProps={{
              "aria-label": "menu-toggle-button",
            }}
            offerBarProps={offerBar}
            variant={header?.variant}
            subNavigation={getSubNavigation(
              header?.subNavigation,
              previewConfig
            )}
          />

          <NavigationDrawer
            data-testid="dotcom-navigation-drawer"
            groups={drawer}
            closeButtonProps={{
              "aria-label": dictionary?.close ?? "Close",
            }}
            backButtonProps={{
              "aria-label": dictionary?.back ?? "Back",
            }}
            header={
              hasMarket && enableLogin ? (
                <LoginButton
                  locale={market.locale}
                  buttonText={dictionary?.signin ?? "Sign in"}
                  onClick={handleLoginButtonClick}
                />
              ) : null
            }
          />
        </>
      ) : null}
    </>
  );
};

export default Navigation;
