import {
  PrimaryAnchorButton,
  EmptyStateSection,
  EmptyStateSectionVariants,
} from "@polestar/component-warehouse-react";

interface Props {
  readonly title?: string;
  readonly message?: string;
  readonly label?: string;
}

const ServerError = ({
  title = "Unexpected error",
  message = "An unexpected error occurred. We have been notified and we are working on a solution.",
  label = "Return Home",
}: Props) => {
  return (
    <EmptyStateSection
      data-testid="dotcom-error-server"
      variant={EmptyStateSectionVariants.withBackgroundImage}
      title={title}
      description={message}
      action={<PrimaryAnchorButton href="/">{label}</PrimaryAnchorButton>}
    />
  );
};

export default ServerError;
