import { Stage } from "~/common/types";

const getEnablePreview = (stage?: string) => {
  return (
    stage === Stage.Playground ||
    stage === Stage.Development ||
    stage === Stage.Staging
  );
};

export default getEnablePreview;
