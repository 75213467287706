import { Link, LinkVariants } from "@polestar/component-warehouse-react";

interface TokenPayload {
  id_token: string;
}

interface IdTokenPayload {
  firstName: string;
  lastName: string;
}

const getCookieValue = (name: string) => {
  return (
    new RegExp("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")
      .exec(document.cookie)
      ?.pop() ?? ""
  );
};

// We need to convert the base64 string to bytes first to support unicode characters
// See https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
const base64ToJSON = <T,>(base64: string) => {
  const binaryString = window.atob(base64);
  const bytesArray = Uint8Array.from(binaryString, (char: string) => {
    /* v8 ignore next */
    return char.codePointAt(0) ?? 0;
  });
  const jsonString = new TextDecoder().decode(bytesArray);

  return JSON.parse(jsonString) as T;
};

// Checks for a "token" cookie. If it exists, the user is logged in.
// If the user is logged in, the name is displayed.
// In any other case we show the regular login button text.
// @TODO: Implement tests for this function
export const getButtonText = (fallback: string) => {
  // We can't determine anything during SSR
  if (typeof window === "undefined") {
    return fallback;
  }

  // It's not a 100% compliant approach to reading JWTs (unicode).
  // But it's good enough for our use-case and saves another dependency.
  // If something goes wrong we'll just show the fallback text.
  try {
    // Read the cookie containing the JWT
    const token = getCookieValue("token");

    if (!token) {
      return fallback;
    }

    const { id_token } = JSON.parse(window.atob(token)) as TokenPayload;

    const idTokenPayload = id_token.split(".")[1];
    const { firstName, lastName } =
      base64ToJSON<IdTokenPayload>(idTokenPayload);

    return `${firstName} ${lastName}`;
  } catch (error) {
    console.error(error);
    return fallback;
  }
};

interface Props {
  readonly locale: string;
  readonly buttonText: string;
  readonly onClick?: () => void;
}

const LoginButton = ({ locale, buttonText, onClick }: Props) => {
  return (
    <Link
      variant={LinkVariants.navigation}
      href={`/${locale}/auth/login?to=/${locale}/login/profile`}
      onClick={onClick}
    >
      {getButtonText(buttonText)}
    </Link>
  );
};

export default LoginButton;
