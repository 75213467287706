/**
 * Converts a focal point to a CSS value.
 *
 * @returns A CSS value for the object-position property.
 */
const convertFocalPointToCssObjectPosition = (focalPoint?: {
  x: number;
  y: number;
}) => {
  if (!focalPoint) {
    return "50% 50%";
  }

  return `${100 * Number(focalPoint.x)}% ${100 * Number(focalPoint.y)}%`;
};

export default convertFocalPointToCssObjectPosition;
