import { SessionStorageKey, type UrlDevice } from "./types";

const getDeviceFromSessionStorage = (): UrlDevice | undefined => {
  try {
    return sessionStorage.getItem(SessionStorageKey.Device) as
      | UrlDevice
      | undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export default getDeviceFromSessionStorage;
