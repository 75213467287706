import { isFullUrl } from "../isFullUrl";

type ParsedURL = URL & {
  isAbsolute: boolean;
};

const getParsedUrl = (url: string) => {
  try {
    const isAbsolute = isFullUrl(url);
    const baseUrl = isAbsolute ? undefined : "https://polestar.com";

    // This will throw a TypeError if the URL is invalid.
    const parsed: Partial<ParsedURL> = new URL(url, baseUrl);
    parsed.isAbsolute = isAbsolute;

    return parsed as ParsedURL;
  } catch (error) {
    console.error(`An error occurred while parsing the URL: ${url}`, error);
  }
};

/**
 * Add trailing slash if the URL pathname doesn't end with a slash.
 * @example
 * addTrailingSlash("https://polestar.com/nl/precept");
 * // "https://polestar.com/nl/precept/"
 * addTrailingSlash("/nl/precept");
 * // "/nl/precept/"
 * addTrailingSlash("/nl/precept#hash");
 * // "/nl/precept/#hash"
 * addTrailingSlash("/nl/precept?search=parameters&extra=bar#hash");
 * // "/nl/precept/?search=parameters&extra=bar#hash"
 * @param url
 */
const addTrailingSlash = (url: string): string => {
  const parsedUrl = getParsedUrl(url);

  if (!parsedUrl) {
    return url;
  }

  let result = parsedUrl.href;

  if (!parsedUrl.pathname.endsWith("/")) {
    result = parsedUrl.href.replace(
      parsedUrl.pathname,
      `${parsedUrl.pathname}/`
    );
  }

  if (parsedUrl.isAbsolute) {
    return result;
  }

  return result.replace(parsedUrl.origin, "");
};

export default addTrailingSlash;
