import styled from "@emotion/styled";
import {
  Image,
  Spacings,
  MediaQueries,
  Grid,
  Column,
  Paragraph,
  Section,
  GridColumn,
  Heading,
  Spacer,
  Orientations,
  Sizes,
  ButtonVariants,
} from "@polestar/component-warehouse-react";
import Button, { ButtonProps } from "../Button/Button";

interface Props {
  readonly title?: string;
  readonly message?: string;
  readonly imageSrc?: string;
  readonly imageAlt?: string;
  readonly button?: ButtonProps;
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: ${Spacings.xxxLarge};
  padding-bottom: ${Spacings.xxxLarge};
  ${MediaQueries.tablet} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledGrid = styled(Grid)`
  display: block;
  ${MediaQueries.tablet} {
    display: grid;
  }
`;

const StyledContentColumn = styled(Column)`
  ${MediaQueries.tablet} {
    height: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: inline-block;
`;

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
`;

const ImageContainer = styled.div`
  margin-top: ${Spacings.xxLarge};
  ${MediaQueries.tablet} {
    margin-top: 0;
    margin-left: -${Spacings.xxLarge};
    margin-right: -${Spacings.xxLarge};
  }
  ${MediaQueries.desktop} {
    margin-left: 0;
    margin-right: -${Spacings.xxxxLarge};
  }
`;

const NotFoundError = ({
  title = "Not found",
  message = "We're unable to find the page you are looking for. Press the link below to get to the home page.",
  imageSrc,
  imageAlt,
  button,
}: Props) => {
  return (
    <Wrapper data-testid="dotcom-error-not-found">
      <Section>
        <StyledGrid>
          <GridColumn span={{ mobile: 4 }}>
            <StyledContentColumn stretch mainAxisAlignment="center">
              <Heading>{title}</Heading>
              <Spacer
                orientation={Orientations.vertical}
                spacing={Spacings.large}
              />
              {message && (
                <StyledParagraph size={Sizes.medium}>{message}</StyledParagraph>
              )}
              <Spacer
                orientation={Orientations.vertical}
                spacing={Spacings.xLarge}
              />

              {button && (
                <ButtonWrapper>
                  <Button
                    variant={ButtonVariants.primary}
                    href={button.href}
                    icon={button.icon}
                    label={button.label}
                    openInNewTab={button.openInNewTab}
                  />
                </ButtonWrapper>
              )}
            </StyledContentColumn>
          </GridColumn>
          <GridColumn
            span={{
              desktop: 8,
              tablet: 8,
              mobile: 4,
            }}
          >
            <StyledContentColumn stretch mainAxisAlignment="center">
              <ImageContainer>
                {imageSrc && <Image alt={imageAlt} src={imageSrc} />}
              </ImageContainer>
            </StyledContentColumn>
          </GridColumn>
        </StyledGrid>
      </Section>

      <Spacer
        spacing={{
          mobile: Spacings.none,
          desktop: Spacings.xxxxLarge,
        }}
      />
    </Wrapper>
  );
};

export default NotFoundError;
