/**
 * Creates a canonical link tag for Remix meta.
 */
export const getCanonicalLink = (pathname: string, isChina: boolean) => {
  const domain = isChina ? "cn" : "com";

  const pathnameWithTrailingSlash = pathname.endsWith("/")
    ? pathname
    : `${pathname}/`;

  const canonicalUrl = `https://www.polestar.${domain}${pathnameWithTrailingSlash}`;

  return {
    tagName: "link",
    rel: "canonical",
    href: canonicalUrl,
  };
};
