enum InViewThreshold {
  none = 0,
  xEarly = 0.1,
  early = 0.25,
  normal = 0.35,
  late = 0.5,
  xLate = 0.7,
  fullyInView = 1,
}

export default InViewThreshold;
