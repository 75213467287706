/**
 * Heavily based on the gist from the Remix meta documentation
 * https://remix.run/docs/en/main/route/meta#meta-merging-helper
 */

import type {
  LoaderFunction,
  MetaDescriptor,
  MetaFunction,
} from "@remix-run/node";

/**
 * Merges meta from parent routes with the current route overrides.
 */
export const mergeMeta = <
  Loader extends LoaderFunction,
  ParentsLoaders extends Record<string, LoaderFunction>,
>(
  overrideFn: MetaFunction<Loader, ParentsLoaders>
): MetaFunction<Loader, ParentsLoaders> => {
  return (metaArguments) => {
    const mergedMeta: MetaDescriptor[] = [];

    // get meta from parent routes
    for (const match of metaArguments.matches) {
      mergedMeta.push(...match.meta);
    }

    // replace any parent meta with the same name or property with the override
    const overrides = overrideFn(metaArguments);

    for (const override of overrides) {
      const index = mergedMeta.findIndex((meta) => {
        return (
          ("name" in meta &&
            "name" in override &&
            meta.name === override.name) ||
          ("property" in meta &&
            "property" in override &&
            meta.property === override.property) ||
          ("title" in meta && "title" in override)
        );
      });

      if (index === -1) {
        mergedMeta.push(override);
      } else {
        mergedMeta.splice(index, 1, override);
      }
    }

    return mergedMeta;
  };
};
