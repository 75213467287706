interface Props {
  /**
   * The Script Components to load. (OneTrustScript, PolestarTagManagerScript, GoogleTagManagerScript)
   */
  readonly children: React.ReactNode;
  /**
   * If true, the scripts will not be loaded.
   *
   * @defaultValue `false`
   */
  readonly isDevelopment: boolean;
}

/**
 * This component is used to load third party scripts.
 * Currently, this particular component only togggles the
 * script loading based on the environment(dev/prod).
 *
 * In the future, we can add more logic to this component (partytown etc.)
 *
 * @example
 * ```
 * <ThirdPartyScripts isDevelopment={isDevelopment}>
 *   <OneTrustScript isChina={false} />
 *   <PolestarTagManagerScript />
 *   <GoogleTagManagerScript />
 * </ThirdPartyScripts>
 * ```
 */
const ThirdPartyScripts = ({ children, isDevelopment }: Props) => {
  if (isDevelopment) {
    return null;
  }

  // Necessary as the typing will complain about returning an array of children
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default ThirdPartyScripts;
