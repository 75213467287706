import styled from "@emotion/styled";
import {
  BannerBar,
  BannerBarVariants,
  Paragraph,
  Spacer,
  Spacings,
} from "@polestar/component-warehouse-react";
import { useState } from "react";

interface Props {
  readonly isPreview: boolean;
  readonly previewEndpoint: string;
  readonly projectCode?: string | null;
  readonly defaultProjectCode?: string | null;
}

const StyledButton = styled.a`
  text-decoration: underline;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
`;

const StyledBannerBar = styled(BannerBar)`
  height: 0px;
`;

const PreviewBanner = ({
  isPreview,
  previewEndpoint,
  projectCode,
  defaultProjectCode,
}: Props) => {
  const [isHidden, setIsHidden] = useState(false);

  if (isHidden) return null;

  const handleClose = () => {
    setIsHidden(true);
  };

  const showProjectCode = projectCode !== defaultProjectCode;

  return (
    <StyledBannerBar
      withCloseButton
      variant={BannerBarVariants.critical}
      message={
        <>
          {showProjectCode && (
            <Paragraph data-testid="project-code-override">
              - You are currently viewing data from an alternative record in
              Dato (project code: <b>{projectCode}</b>).&nbsp;
              <StyledButton href={`?projectCode=${defaultProjectCode}`}>
                Go back to production record
              </StyledButton>
            </Paragraph>
          )}

          {showProjectCode && isPreview && <Spacer spacing={Spacings.xSmall} />}

          {isPreview && (
            <Paragraph>
              - You are viewing this page in <b>preview mode</b>. This means you
              are seeing saved instead of published data from Dato.&nbsp;
              <StyledButton
                data-testid="preview-banner-exit"
                aria-label="Exit preview mode"
                href={previewEndpoint}
              >
                Click here to exit preview mode
              </StyledButton>
            </Paragraph>
          )}
        </>
      }
      onClose={handleClose}
    />
  );
};

export default PreviewBanner;
