interface Props {
  /**
   * Indicates if this is running on polestar.cn
   */
  readonly isChina: boolean;
}

/**
 * Only use this component as a child of the ThirdPartyScripts component.
 *
 * @example
 * ```
 * <ThirdPartyScripts isDevelopment={isDevelopment}>
 *   <OneTrustScript isChina={false} />
 * </ThirdPartyScripts>
 * ```
 */
const OneTrustScript = ({ isChina }: Props) => {
  if (isChina) {
    return null;
  }

  return (
    <script
      async
      type="text/javascript"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-domain-script="2b8f0235-bba3-48f8-b40b-77394cd12e03"
      data-testid="one-trust-script"
    />
  );
};

export default OneTrustScript;
