interface Market {
  locale: string;
  languageCode: string;
  countryCode: string;
}

/**
 * Converts a relative path to an absolute URL with proper locale and domain
 */
export const getFullUrl = (
  pathname: string,
  locale: string,
  markets: Market[],
  isChina: boolean
) => {
  if (pathname.startsWith("http://") || pathname.startsWith("https://")) {
    return pathname;
  }

  const domain = isChina ? "cn" : "com";
  const baseUrl = `https://www.polestar.${domain}/${locale}`;
  const splitPathname = pathname.split("/").filter(Boolean);

  const locales = markets.map(({ locale }) => locale);
  const pathLocale = splitPathname[0];

  // Remove any potential locale from the pathname
  if (locales.includes(pathLocale)) {
    splitPathname.splice(0, 1);
  }

  const formattedPathname = splitPathname.join("/");

  return !formattedPathname
    ? `${baseUrl}/`
    : `${baseUrl}/${formattedPathname}/`;
};

export default getFullUrl;
