import { ThemeTypes } from "@polestar/component-warehouse-react";

import { type VideoAutoplayProps } from "../VideoAutoplay";

const shortVideoAutoplayMockData: VideoAutoplayProps = {
  video: {
    sources: {
      mobile: {
        url: "https://www.datocms-assets.com/94392/1710171229-placeholder-mobile.mp4",
        duration: 5,
        createdAt: "2021-10-18T09:00:00Z",
      },
      tablet: {
        url: "https://www.datocms-assets.com/94392/1710171229-placeholder-tablet.mp4",
        duration: 5,
        createdAt: "2021-10-18T09:00:00Z",
      },
      desktop: {
        url: "https://www.datocms-assets.com/94392/1710171229-placeholder-desktop.mp4",
        duration: 5,
        createdAt: "2021-10-18T09:00:00Z",
      },
    },
    firstFrame: {
      mobile: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-mobile.jpg",
        alt: "first frame mobile image",
      },
      tablet: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-tablet.jpg",
        alt: "first frame tablet image",
      },
      desktop: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-desktop.jpg",
        alt: "first frame desktop image",
      },
      desktopWide: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-desktop-wide.jpg",
        alt: "first frame desktopWide image",
      },
    },
    poster: {
      mobile: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-mobile.jpg",
        alt: "poster mobile image",
      },
      tablet: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-tablet.jpg",
        alt: "poster tablet image",
      },
      desktop: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-desktop.jpg",
        alt: "poster desktop image",
      },
      desktopWide: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-desktop-wide.jpg",
        alt: "poster desktopWide image",
      },
    },
    autoplay: {
      theme: {
        mobile: ThemeTypes.dark,
        tablet: ThemeTypes.dark,
        desktop: ThemeTypes.dark,
      },
      loop: true,
    },
  },
  ariaLabels: {
    play: "Play",
    pause: "Pause",
  },
};

const longVideoAutoplayMockData: VideoAutoplayProps = {
  video: {
    sources: {
      mobile: {
        url: "https://www.datocms-assets.com/94392/1710171229-placeholder-mobile.mp4",
        duration: 5,
        createdAt: "2021-10-18T09:00:00Z",
      },
      tablet: {
        url: "https://www.datocms-assets.com/94392/1710171229-placeholder-tablet.mp4",
        duration: 5,
        createdAt: "2021-10-18T09:00:00Z",
      },
      desktop: {
        url: "https://www.datocms-assets.com/94392/1710171229-placeholder-desktop.mp4",
        duration: 5,
        createdAt: "2021-10-18T09:00:00Z",
      },
    },
    firstFrame: {
      mobile: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-mobile.jpg",
        alt: "first frame mobile image",
      },
      tablet: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-tablet.jpg",
        alt: "first frame tablet image",
      },
      desktop: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-desktop.jpg",
        alt: "first frame desktop image",
      },
      desktopWide: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/first-frame-placeholder-desktop-wide.jpg",
        alt: "first frame desktopWide image",
      },
    },
    poster: {
      mobile: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-mobile.jpg",
        alt: "poster mobile image",
      },
      tablet: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-tablet.jpg",
        alt: "poster tablet image",
      },
      desktop: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-desktop.jpg",
        alt: "poster desktop image",
      },
      desktopWide: {
        id: "1",
        focalPoint: { x: 0.5, y: 0.5 },
        url: "https://www.datocms-assets.com/poster-placeholder-desktop-wide.jpg",
        alt: "poster desktopWide image",
      },
    },
    autoplay: {
      theme: {
        mobile: ThemeTypes.light,
        tablet: ThemeTypes.dark,
        desktop: ThemeTypes.dark,
      },
      loop: true,
    },
  },
  ariaLabels: {
    play: "Play",
    pause: "Pause",
  },
};

export { shortVideoAutoplayMockData };
export default longVideoAutoplayMockData;
