import { useVideoPlayerAutoplay } from "@polestar/component-warehouse-react";
import { useEffect } from "react";

import useVideoAutoplayEventListeners from "./useVideoAutoplayEventListeners";

/**
 * Autoplay video
 * The logic to autoplay the video when shouldAutoPlay is true.
 * This hook will NOT consider reduced motion, when reduced motion is enabled make sure shouldAutoPlay is false.
 */
const useAutoplayVideo = ({
  videoRef,
  shouldAutoPlay = true,
}: {
  videoRef: React.RefObject<HTMLVideoElement>;
  // Can be used in combination with isInView, to only trigger autoplay when the video is inView.
  shouldAutoPlay?: boolean;
}) => {
  const { canPlayThrough } = useVideoAutoplayEventListeners(videoRef);
  const { play, pause } = useVideoPlayerAutoplay(videoRef);
  const videoElement = videoRef.current;

  /**
   * Force video load for browser that doesn't load the video automatically (e.g. Safari).
   */
  useEffect(() => {
    if (videoElement && !canPlayThrough) {
      videoElement.load();
    }
  }, [shouldAutoPlay, canPlayThrough, videoRef, videoElement]);

  useEffect(() => {
    if (!videoElement || !canPlayThrough) {
      // When there is no video element return early.
      return undefined;
    }

    const isVideoPaused = videoElement.paused;

    if (!isVideoPaused && !shouldAutoPlay) {
      try {
        pause();
      } catch (error) {
        console.error(error);
      }
    }

    if (isVideoPaused && shouldAutoPlay) {
      try {
        play();
      } catch (error) {
        console.error(error);
      }
    }
  }, [canPlayThrough, pause, play, shouldAutoPlay, videoElement, videoRef]);
};

export default useAutoplayVideo;
