import { type Market } from "@dotcom/markets";
import { removeLocaleFromPath } from "@dotcom/url";
import {
  Footer as CWFooter,
  useInView,
  MarketSelector,
  useCombinedRef,
  ParsedImporterBanner,
  ImporterBannerBottom,
} from "@polestar/component-warehouse-react";
import { forwardRef, useEffect, useState } from "react";

import { useDeviceFromUrl } from "../hooks";

import DeveloperMetadata from "./DeveloperMetadata";
import NewsletterBanner from "./NewsletterBanner";
import { useTracking } from "../TrackingProvider/TrackingProvider";

interface FooterItem {
  copyrightText?: string;
  disclaimerText?: string;
  icpLink?: { label: string; href: string };
  legalLinks?: {
    items: Array<{
      label: string;
      href: string;
    }>;
  };
}

interface DoormatItem {
  items: Array<{
    label: string;
    href: string;
    items?: DoormatItem[];
  }>;
}

interface Props {
  readonly market?: Market;
  readonly pathname: string;
  readonly footer?: FooterItem;
  readonly doormat?: DoormatItem[];
  readonly newsletterSignup?: {
    title?: string;
    buttonLabel: string;
    buttonUrl?: string;
  };
  readonly marketSelector?: {
    regions?: Array<{
      title: string;
      markets: Array<{ label: string; url: string }>;
    }>;
    title?: string;
    globalLocale?: string;
    visitGlobalSiteText?: string;
  };
  readonly previewConfig?: {
    enablePreview?: boolean;
    isPreview?: boolean;
    previewApiEndpoint?: string;
    ephemeralDeploymentId?: string;
  };
  readonly importerBanner?: ParsedImporterBanner;
}

const toggleChatButtonVisibility = (isVisible: boolean): void => {
  const chatButtonPlaceholder = document.querySelector<HTMLElement>(
    ".chat-button-placeholder--floating"
  );

  if (chatButtonPlaceholder) {
    chatButtonPlaceholder.style.opacity = isVisible ? "" : "0";
    chatButtonPlaceholder.style.pointerEvents = isVisible ? "" : "none";
  }
};

const Footer = forwardRef<HTMLDivElement, Props>(
  (
    {
      market,
      pathname,
      footer,
      doormat,
      newsletterSignup,
      marketSelector,
      previewConfig,
      importerBanner,
    },
    forwardRef
  ) => {
    const device = useDeviceFromUrl();
    const [footerInViewRef, isFooterInView] = useInView<HTMLDivElement>({});
    const [isFooterInViewTracked, setIsFooterInViewTracked] = useState(false);
    const footerRef = useCombinedRef(forwardRef, footerInViewRef);
    const { pushToDatalayer } = useTracking();

    useEffect(() => {
      toggleChatButtonVisibility(!isFooterInView);

      if (isFooterInView && !isFooterInViewTracked) {
        pushToDatalayer({
          event: "section.elementVisibility",
          sectionId: "web-section-footer",
        });
        setIsFooterInViewTracked(true);
      }
    }, [isFooterInView, isFooterInViewTracked, footerRef, pushToDatalayer]);

    // Don't render any navigation on e.g. space tabletops or space tablets
    if (device) {
      return null;
    }

    return (
      <>
        {importerBanner && (
          <ImporterBannerBottom
            data-testid="dotcom-importer-banner-bottom"
            message={importerBanner.message}
            logo={importerBanner.logo}
          />
        )}
        <CWFooter
          ref={footerRef}
          data-testid="dotcom-footer"
          copyrightText={footer?.copyrightText}
          disclaimerText={footer?.disclaimerText}
          icpLink={footer?.icpLink}
          legalLinks={footer?.legalLinks}
          listGroups={doormat}
          banner={
            newsletterSignup?.title &&
            newsletterSignup.buttonLabel &&
            newsletterSignup.buttonUrl ? (
              <NewsletterBanner
                title={newsletterSignup.title}
                buttonLabel={newsletterSignup.buttonLabel}
                buttonUrl={newsletterSignup.buttonUrl}
                redirectUrl={pathname}
              />
            ) : undefined
          }
          marketSelector={
            market?.marketName &&
            market?.locale &&
            marketSelector?.regions &&
            marketSelector.title &&
            marketSelector.globalLocale &&
            marketSelector.visitGlobalSiteText ? (
              <MarketSelector
                data-testid="dotcom-market-selector"
                activeMarketLabel={market.marketName}
                drawerProps={{
                  regions: marketSelector.regions,
                  title: marketSelector.title,
                  global: {
                    label: marketSelector.globalLocale,
                    visitGlobalSiteText: marketSelector.visitGlobalSiteText,
                    url: `/global${removeLocaleFromPath(pathname, market.locale)}`,
                  },
                }}
              />
            ) : null
          }
        />

        {previewConfig?.enablePreview && previewConfig.previewApiEndpoint ? (
          <DeveloperMetadata
            isPreview={previewConfig.isPreview ?? false}
            previewEndpoint={previewConfig.previewApiEndpoint}
            ephemeralDeploymentId={previewConfig.ephemeralDeploymentId}
          />
        ) : null}
      </>
    );
  }
);

Footer.defaultProps = {
  market: undefined,
  footer: undefined,
  doormat: [],
  newsletterSignup: undefined,
  marketSelector: undefined,
  previewConfig: undefined,
  importerBanner: undefined,
};

export default Footer;
