import { isFullUrl } from "../isFullUrl";

const getLocalizedUrl = (url: string, locale: string): string => {
  // if url is a full URL or already has the locale, return url
  if (isFullUrl(url) || url.startsWith(`/${locale}`)) {
    return url;
  }

  // prefix url with locale
  return `/${locale}${url}`;
};

export default getLocalizedUrl;
