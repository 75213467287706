import { useEffect, useState } from "react";

import getDeviceFromSessionStorage from "./getDeviceFromSessionStorage";
import { QueryParameter, SessionStorageKey, UrlDevice } from "./types";

const useDeviceFromUrl = (): UrlDevice | undefined => {
  const [device, setDevice] = useState<UrlDevice>();

  useEffect(() => {
    const urlSearchParameters = new URLSearchParams(window.location.search);
    const urlSearchParameterDevice = urlSearchParameters.get(
      QueryParameter.Device
    );

    // Try the URL
    if (
      urlSearchParameterDevice === UrlDevice.Portal ||
      urlSearchParameterDevice === UrlDevice.Event
    ) {
      setDevice(urlSearchParameterDevice);
      sessionStorage.setItem(
        SessionStorageKey.Device,
        urlSearchParameterDevice
      );

      return;
    }

    // Try the session storage
    const sessionStorageDevice = getDeviceFromSessionStorage();
    if (
      sessionStorageDevice === UrlDevice.Portal ||
      sessionStorageDevice === UrlDevice.Event
    ) {
      setDevice(sessionStorageDevice);
    }
  }, []);

  return device;
};

export default useDeviceFromUrl;
