import styled from "@emotion/styled";
import {
  Heading,
  Table,
  MediaQueries,
  TableHeader,
  HorizontalScrollView,
  Colors,
  TableCell,
  Spacings,
} from "@polestar/component-warehouse-react";

const cellSpacing = `
  ${MediaQueries.mobile} {
    max-width: 40vw;
    padding: ${Spacings.medium} ${Spacings.large} ${Spacings.medium} ${Spacings.none};
  }

   ${MediaQueries.tablet} {
    padding: ${Spacings.large} ${Spacings.xLarge} ${Spacings.large} ${Spacings.none};
  }
`;

const StyledTable = styled(Table)`
  width: 100%;

  ${MediaQueries.tablet} {
    table-layout: fixed;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  background-color: ${Colors.transparent};

  ${cellSpacing}
  ${MediaQueries.mobile} {
    padding-top: ${Spacings.none};
  }
`;

const StyledHeading = styled(Heading)`
  white-space: pre-line;
`;

const StyledHorizontalScrollView = styled(HorizontalScrollView)`
  width: 100%;
`;

const StyledTableCell = styled(TableCell)`
  ${cellSpacing};
`;

export {
  StyledTableCell,
  StyledTable,
  StyledHorizontalScrollView,
  StyledHeading,
  StyledTableHeader,
};
