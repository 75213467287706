import { addTrailingSlash, getLocalizedUrl } from "@dotcom/url";
import styled from "@emotion/styled";
import {
  Alignments,
  createCustomSpacing,
  Grid,
  GridColumn,
  Heading,
  Icons,
  Paragraph,
  Sizes,
  Spacings,
  TextAnchorButton,
} from "@polestar/component-warehouse-react";

import { type LegalLandingPageQueryResponse } from "../../data/landingPage-generated";

type TopicType = NonNullable<
  LegalLandingPageQueryResponse["legalLandingPage"]
>["topics"][number];

interface TopicProps extends Omit<TopicType, "id"> {
  readonly locale?: string;
}

const StyledParagraph = styled(Paragraph)`
  padding-top: ${Spacings.medium};
  padding-bottom: ${createCustomSpacing(1.25)};
`;

const StyledWrapper = styled("div")`
  display: block;
`;

const Topic = ({
  body,
  linkLabel,
  linkUrl,
  title,
  locale,
}: TopicProps): JSX.Element => {
  const localizedUrl = getLocalizedUrl(linkUrl ?? "", locale ?? "");

  return (
    <Grid nested as="li">
      <GridColumn
        span={{
          mobile: 4,
          tablet: 5,
          desktop: 6,
        }}
      >
        <StyledWrapper>
          {title && (
            <Heading level={3} size={Sizes.medium}>
              {title}
            </Heading>
          )}
          {body && <StyledParagraph>{body}</StyledParagraph>}
          {linkUrl && linkLabel && (
            <TextAnchorButton
              href={addTrailingSlash(localizedUrl)}
              icon={Icons.arrowRight}
              iconPosition={Alignments.right}
            >
              {linkLabel}
            </TextAnchorButton>
          )}
        </StyledWrapper>
      </GridColumn>
    </Grid>
  );
};

export default Topic;
export type { TopicProps };
