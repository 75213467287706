import { getPageMeta } from "./getPageMeta";

/**
 * Returns fallback Remix meta array, to be used when no meta tags are provided.
 * This function should be used on `root` level in all applications.
 */
export const getFallbackMeta = (image?: string | null) => {
  const title = "Pure progressive performance | Polestar";
  const description =
    "Passion and emotion drive us, electricity and innovation drive our cars. We are all in, guiding our industry forward through pure, progressive, performance.";
  const fallbackImage =
    "https://www.polestar.com/dato-assets/11286/1673895165-polestar-seo-fallback.jpg?q=60&dpr=1&w=1920";

  return getPageMeta({
    title,
    description,
    image: image ?? fallbackImage,
  });
};
