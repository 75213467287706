/* v8 ignore start - Nothing to test, this is purely css-based and uses CW helpers */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  type ResponsiveThemeData,
  responsiveStyles,
} from "@polestar/component-warehouse-react";

const DisplayOnBreakpoints = styled("div", {
  shouldForwardProp(property: string) {
    return property !== "display";
  },
})<{ display: ResponsiveThemeData<string> }>(({ display }) => {
  return css`
    ${responsiveStyles({
      display,
    })}
  `;
});

export default DisplayOnBreakpoints;
/* v8 ignore stop */
