/**
 * Converts common image properties into a source set. for use in an <img> tag.
 */
const getSourceSet = (
  image: { id: string; url: string },
  width: number,
  breakpoint: number
) => {
  return {
    id: `${image.id}-${breakpoint}-${width}`,
    media: `(min-width: ${breakpoint}px)`,
    srcSet: `${image.url}?q=60&dpr=1&w=${width} 1x, ${image.url}?q=35&dpr=2&w=${width} 2x`,
  };
};

export default getSourceSet;
