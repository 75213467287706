import { Breakpoints } from "@polestar/component-warehouse-react";

import { type ParsedImageSet } from "../../types";
import convertFocalPointToCssObjectPosition from "../convertFocalPointToCssObjectPosition/convertFocalPointToCssObjectPosition";
import getSourceSet from "../getSourceSet/getSourceSet";

const defaultWidths = {
  desktopWide: 1920,
  desktop: 1440,
  tablet: 900,
  mobile: 600,
};

/**
 * Takes the data from `parseDatoImage` to generate source sets, focal points and responsive alt text for your responsive image.
 *
 * @param {Object} params
 * @param {Object} params.imageSet - The URL of each image by device name.
 * @param {Object} params.widths - The width of each image by device name.
 * @param {string} params.deviceName - The current device name to get the correct alt text and object position. Obtain by using `useMediaQuery` from Component Warehouse.
 */
const getResponsiveImageData = ({
  imageSet,
  widths = defaultWidths,
  deviceName,
}: {
  imageSet: ParsedImageSet;
  readonly widths?: {
    desktopWide?: number;
    desktop?: number;
    tablet?: number;
    mobile?: number;
  };
  deviceName?: keyof ParsedImageSet;
}) => {
  const { desktop, desktopWide, tablet, mobile } = imageSet;
  const currentImage = deviceName ? imageSet[deviceName] : desktop;

  const sources = [
    getSourceSet(
      desktopWide,
      widths?.desktopWide ?? defaultWidths.desktopWide,
      Breakpoints.desktopWide
    ),
    getSourceSet(
      desktop,
      widths?.desktop ?? defaultWidths.desktop,
      Breakpoints.desktop
    ),
    getSourceSet(
      tablet,
      widths?.tablet ?? defaultWidths.tablet,
      Breakpoints.tablet
    ),
    getSourceSet(
      mobile,
      widths?.mobile ?? defaultWidths.mobile,
      Breakpoints.mobile
    ),
  ];

  return {
    sources,
    src: desktop.url + `?q=60&dpr=1&w=${widths.desktop}`,
    alt: currentImage.alt,
    objectPosition: convertFocalPointToCssObjectPosition(
      currentImage.focalPoint
    ),
  };
};

export default getResponsiveImageData;
