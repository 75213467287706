export enum UrlDevice {
  Portal = "portal",
  Event = "event",
}

export enum QueryParameter {
  Device = "device",
}

export enum SessionStorageKey {
  Device = "dotcom-device",
}
