import { type ChangeEvent, useEffect, useState } from "react";

/**
 * Listen to video events and update state.
 */
const useVideoAutoplayEventListeners = (
  videoRef: React.RefObject<HTMLVideoElement>
) => {
  const [duration, setDuration] = useState<number | null>(null);
  const [canPlayThrough, setCanPlayThrough] = useState(false);

  useEffect(() => {
    const videoElement = videoRef?.current;

    const handleCanPlayThrough = () => {
      setCanPlayThrough(true);
    };

    const handleDurationChange = ({
      target,
    }: ChangeEvent<HTMLVideoElement>) => {
      setDuration(target.duration);
    };

    videoElement?.addEventListener("canplaythrough", handleCanPlayThrough);

    // Duration change listner will always give ChangeEvent<HTMLVideoElement>, unfortunatly React does not use this.
    // @ts-expect-error wrong event type
    videoElement?.addEventListener("durationchange", handleDurationChange);

    return () => {
      videoElement?.removeEventListener("canplaythrough", handleCanPlayThrough);

      // Duration change listner will always give ChangeEvent<HTMLVideoElement>, unfortunatly React does not use this.
      // @ts-expect-error wrong event type
      videoElement?.removeEventListener("durationchange", handleDurationChange);
    };
  }, [videoRef]);

  return {
    canPlayThrough,
    duration,
  };
};

export default useVideoAutoplayEventListeners;
