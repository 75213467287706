import { css, ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import {
  MotionDurations,
  PauseButton,
  Spacings,
  ThemeTypes,
  useInView,
  useReducedMotion,
  responsiveStyles,
  type ResponsiveThemeData,
  Breakpoints,
  type PauseButtonProps,
  type ImageProps,
  Themes,
  useMediaQuery,
  useCombinedRef,
  LayoutIndexes,
} from "@polestar/component-warehouse-react";
import { type MutableValue } from "@polestar/component-warehouse-react/hooks/useMutableValue";
import { forwardRef, useRef, useState, type ChangeEvent } from "react";

import { convertFocalPointToCssObjectPosition } from "../helpers";
import useAutoplayVideo from "../hooks/useAutoplay/useAutoplayVideo";
import Image from "../Image/Image";
import InViewThreshold from "../tokens/InViewThreshold";
import { type ParsedImageSet } from "../types";
import { type ParsedAutoplayVideo } from "../types/Video";

import { useVideoGlobalPause } from "./VideoAutoplayProvider";

export interface VideoAutoplayProps {
  readonly video: ParsedAutoplayVideo;
  readonly ariaLabels: Required<PauseButtonProps["ariaLabels"]>;
  readonly videoPreload?: "none" | "metadata" | "auto";
  readonly imageLoading?: ImageProps["loading"];
}

const getImageSrc = (
  shouldAutoPlay: boolean,
  firstFrameImage: ParsedImageSet | undefined,
  posterImage: ParsedImageSet | undefined
) => {
  /**
   * We only want to check this on the client side to prevent a layout shift when the video is able to autoplay.
   * If the video is not able to autoplay, we want to show the poster image if it's available.
   */
  if (typeof window !== "undefined" && !shouldAutoPlay && posterImage) {
    return posterImage;
  }

  return firstFrameImage;
};

/**
 * Will return the play pause button visibility based on the video and user preferences.
 */
export const getPlayPauseButtonVisibility = ({
  loop,
  userPrefersReducedMotion,
  duration,
}: {
  loop: boolean;
  userPrefersReducedMotion: boolean;
  duration: number | null;
}) => {
  // Always show button when loop is enabled or the user prefers reduced motion
  if (loop || userPrefersReducedMotion) {
    return true;
  }

  // When the video is shorter then 5 seconds, the button will be hidden
  if (!duration || (duration && duration <= 5)) {
    return false;
  }

  return true;
};

/**
 * Handles different scenarios to determine if the video should autoplay.
 */
export const getShouldAutoplay = ({
  userPrefersReducedMotion,
  isInView,
  playPauseButtonVisibility,
  isGlobalPause,
}: {
  userPrefersReducedMotion: boolean;
  isInView: boolean | MutableValue<boolean>;
  playPauseButtonVisibility: boolean;
  isGlobalPause: boolean;
}) => {
  // When the user prefers reduced motion, the video should not autoplay.
  if (userPrefersReducedMotion) {
    return false;
  }

  // Non-looping videos should not pause when the global pause is active. (PCOM-14207)
  if (!playPauseButtonVisibility && Boolean(isInView)) {
    return true;
  }

  // When the video is in view and the global pause is not active, the video should autoplay.
  return Boolean(isInView) && !isGlobalPause;
};

const Stack = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  overflow: hidden;

  & > * {
    grid-area: 1 / 1;
  }
`;

const Video = styled.video<{
  objectPosition: ResponsiveThemeData<string>;
}>(({ objectPosition }) => {
  return css`
    position: absolute;
    opacity: 1;
    transition: opacity ${MotionDurations.fast}ms ease-in-out;
    object-fit: cover;
    ${responsiveStyles({ objectPosition })};
  `;
});

const StyledPauseButton = styled(PauseButton)<{
  themeType: Required<ResponsiveThemeData<ThemeTypes>>;
}>`
  position: absolute;
  height: 100%;
  ${responsiveStyles({
    bottom: { mobile: Spacings.medium, desktop: Spacings.large },
    right: { mobile: Spacings.medium, desktop: Spacings.large },
  })};
  // The z-index is set to the content level to ensure the button is clickable when text is displayed as an overlay.
  z-index: ${LayoutIndexes.content};
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const getThemeType = (theme: "light" | "dark"): ThemeTypes => {
  return theme === "dark" ? ThemeTypes.dark : ThemeTypes.light;
};

/**
 * The video autoplay component should only be used for decorative purposes
 * the video will be muted, and autoplay (when reducedMotion is disabled).
 *
 * The play/pause button will always be visible when:
 * - reduced motion is enabled
 * - loop is enabled
 *
 * The play/pause button will be hidden when:
 * - loop is disabled and video is less then 5 seconds
 */
const VideoAutoplay = forwardRef<HTMLVideoElement, VideoAutoplayProps>(
  (
    {
      video,
      videoPreload = "metadata",
      imageLoading = "lazy",
      ariaLabels,
    }: VideoAutoplayProps,
    ref
  ) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const combinedRef = useCombinedRef(ref, videoRef);
    const [duration, setDuration] = useState<number | null>(null);
    const [canPlayThrough, setCanPlayThrough] = useState(false);
    const { isGlobalPause, setIsGlobalPause } = useVideoGlobalPause();
    const userPrefersReducedMotion = useReducedMotion();
    const loop = Boolean(!userPrefersReducedMotion && video.autoplay.loop);

    const { name } = useMediaQuery();

    const [wrapperRef, isInView] = useInView<HTMLDivElement>({
      threshold: video.autoplay.loop
        ? InViewThreshold.early
        : InViewThreshold.xLate,
    });

    const playPauseButtonVisibility = getPlayPauseButtonVisibility({
      loop: Boolean(video.autoplay.loop),
      userPrefersReducedMotion,
      duration,
    });

    const shouldAutoPlay = getShouldAutoplay({
      isGlobalPause,
      isInView,
      playPauseButtonVisibility,
      userPrefersReducedMotion,
    });

    useAutoplayVideo({
      videoRef,
      shouldAutoPlay,
    });

    /**
     * Use object position from the firstFrame image for the video.
     */
    const sharedObjectPosition = {
      mobile: convertFocalPointToCssObjectPosition(
        video.firstFrame?.mobile.focalPoint
      ),
      tablet: convertFocalPointToCssObjectPosition(
        video.firstFrame?.tablet.focalPoint
      ),
      desktop: convertFocalPointToCssObjectPosition(
        video.firstFrame?.desktop.focalPoint
      ),
      desktopWide: convertFocalPointToCssObjectPosition(
        video.firstFrame?.desktopWide.focalPoint
      ),
    };

    const handlePauseClick = () => {
      setIsGlobalPause(videoRef.current?.paused || false);
    };

    const handleCanPlayThrough = () => {
      setCanPlayThrough(true);
    };

    const handleDurationChange = ({
      target,
    }: ChangeEvent<HTMLVideoElement>) => {
      setDuration(target.duration);
    };

    const controlsThemeType = {
      mobile: getThemeType(video.autoplay.theme.mobile),
      tablet: getThemeType(video.autoplay.theme.tablet),
      desktop: getThemeType(video.autoplay.theme.desktop),
      desktopWide: getThemeType(video.autoplay.theme.desktop),
    };
    const currentControlsTheme = Themes[controlsThemeType[name]];

    const imageSrc = getImageSrc(
      shouldAutoPlay,
      video.firstFrame,
      video.poster
    );

    return (
      <Stack ref={wrapperRef}>
        <Video
          ref={combinedRef}
          muted
          playsInline
          data-testid="video-autoplay-video"
          autoPlay={false} // See useAutoplayVideo for the autoplay logic.
          controls={false}
          width="100%"
          height="100%"
          loop={loop}
          preload={videoPreload}
          objectPosition={sharedObjectPosition}
          onCanPlayThrough={handleCanPlayThrough}
          onDurationChange={handleDurationChange}
        >
          <track
            label="Decorative video without audio"
            kind="captions"
            srcLang="en"
            src="https://www.polestar.com/shared-assets/vtt/decorative-video.vtt"
          />
          <source
            src={video.sources.desktop.url}
            media={`(min-width: ${Breakpoints.desktop}px)`}
          />
          <source
            src={video.sources.tablet.url}
            media={`(min-width: ${Breakpoints.tablet}px)`}
          />
          <source
            src={video.sources.mobile.url}
            media={`(min-width: ${Breakpoints.mobile}px)`}
          />
        </Video>
        {imageSrc && (
          <ImageWrapper
            aria-hidden
            data-testid="video-autoplay-first-frame"
            style={{ opacity: canPlayThrough ? 0 : 1 }}
          >
            <Image
              imageSet={imageSrc}
              objectFit="cover"
              loading={imageLoading}
            />
          </ImageWrapper>
        )}
        {canPlayThrough && playPauseButtonVisibility && (
          <ThemeProvider theme={currentControlsTheme}>
            <StyledPauseButton
              indicator
              mediaRef={videoRef}
              data-testid="video-autoplay-play-pause-button"
              themeType={controlsThemeType}
              ariaLabels={ariaLabels}
              onClick={handlePauseClick}
            />
          </ThemeProvider>
        )}
      </Stack>
    );
  }
);

export default VideoAutoplay;
