import {
  Alignments,
  Icons,
  PrimaryAnchorButton,
  SecondaryAnchorButton,
  TextAnchorButton,
} from "@polestar/component-warehouse-react";
import React from "react";
import { useTracking } from "../TrackingProvider/TrackingProvider";

export interface ButtonProps {
  readonly label: string;
  readonly href: string;
  readonly variant: "primary" | "secondary" | "text";
  readonly icon?: keyof typeof Icons;
  readonly openInNewTab: boolean;
  readonly monochrome?: boolean;
  readonly className?: string;
  readonly dataLayer?: {
    event: string;
    eventInfo: {
      action: string;
      label: string;
    };
  };
}

// This exists as Component Warehouse prefers us to use the specific button components
// instead of using the base Button with a variant prop.
const ButtonMap = {
  primary: PrimaryAnchorButton,
  secondary: SecondaryAnchorButton,
  text: TextAnchorButton,
};

/**
 * A component that couples with the `parseButton` function in `@dotcom/dato/helpers/parseButton.ts`.
 * which is used to parse the `Shared Button` model uses in DatoCMS.
 */

const Button = React.forwardRef<HTMLAnchorElement, ButtonProps>(
  (
    {
      label,
      href,
      variant,
      icon,
      openInNewTab,
      monochrome,
      className,
      dataLayer,
    },
    ref
  ) => {
    const { pushToDatalayer } = useTracking();
    const AnchorButton = ButtonMap[variant];

    const rel = openInNewTab ? "noopener noreferrer" : undefined;
    const target = openInNewTab ? "_blank" : "_self";
    const iconPosition = icon === "arrowLeft" ? Alignments.left : undefined;

    const handleClick = () => {
      pushToDatalayer(dataLayer);
    };

    return (
      <AnchorButton
        ref={ref}
        rel={rel}
        target={target}
        icon={icon ? Icons[icon] : undefined}
        iconPosition={iconPosition}
        href={href}
        monochrome={monochrome}
        className={className}
        onClick={handleClick}
      >
        {label}
      </AnchorButton>
    );
  }
);

Button.defaultProps = {
  monochrome: false,
  icon: undefined,
  className: undefined,
  dataLayer: undefined,
};

export default Button;
