import {
  Colors,
  CTASection,
  Divider,
  Emphasis,
  Grid,
  GridColumn,
  Heading,
  Sizes,
  Spacer,
  Spacings,
} from "@polestar/component-warehouse-react";
import React from "react";

import Topic, { type TopicProps } from "./Topic";

interface TopicsProps {
  readonly title?: string;
  readonly topics?: Array<TopicProps & { id: string }>;
  readonly locale?: string;
}

const Topics: React.FC<TopicsProps> = ({ title, topics, locale }) => {
  return (
    <CTASection backgroundColor={Colors.signalWhite}>
      <Spacer
        spacing={{
          mobile: Spacings.xxxLarge,
          desktop: Spacings.xxxxLarge,
          tablet: Spacings.xxxLarge,
        }}
      />
      <Grid>
        <GridColumn
          span={{
            mobile: 4,
            tablet: 2,
            desktop: 3,
          }}
        >
          <Heading size={Sizes.medium} level={2}>
            {title}
          </Heading>
        </GridColumn>
        <GridColumn
          as="ul"
          span={{
            mobile: 4,
            tablet: 5,
            desktop: 8,
          }}
          offset={{ mobile: 1 }}
        >
          {topics?.map(
            ({ body, id, linkLabel, linkUrl, title: topicTitle }, index) => {
              return (
                <React.Fragment key={id}>
                  <Topic
                    body={body}
                    linkLabel={linkLabel}
                    linkUrl={linkUrl}
                    title={topicTitle}
                    locale={locale}
                  />
                  {index !== topics.length - 1 && (
                    <Divider
                      as="li"
                      data-testid={`divider-${index}`}
                      role="separator"
                      aria-hidden="true"
                      spacing={Spacings.xxxxLarge}
                      color={Colors.black}
                      emphasis={Emphasis.low}
                    />
                  )}
                </React.Fragment>
              );
            }
          )}
        </GridColumn>
      </Grid>
      <Spacer
        spacing={{
          mobile: Spacings.xxxLarge,
          desktop: Spacings.xxxxLarge,
          tablet: Spacings.xxxLarge,
        }}
      />
    </CTASection>
  );
};

export default Topics;
export type { TopicsProps };
