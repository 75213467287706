import { type LinkDescriptor } from "@remix-run/node";

export const getFaviconLinks = (): LinkDescriptor[] => {
  return [
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: `/w3-assets/favicon-32x32.png`,
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: `/w3-assets/favicon-16x16.png`,
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: `/w3-assets/apple-touch-icon.png`,
    },
  ];
};
