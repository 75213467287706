import { useEffect } from "react";

interface Props {
  readonly isChina: boolean;
}

/**
 * Only use this component as a child of the ThirdPartyScripts component.
 *
 * @example
 * ```
 * <ThirdPartyScripts isDevelopment={isDevelopment}>
 *   <GoogleTagManager />
 * </ThirdPartyScripts>
 * ```
 */
const GoogleTagManagerScript = ({ isChina }: Props) => {
  /**
   * GTM
   * Need to create GTM script client-side (useEffect), due to hydration issues
   * Discussion: https://github.com/remix-run/remix/discussions/3179
   **/
  useEffect(() => {
    if (!isChina) {
      const gtmScript = document.createElement("script");

      gtmScript.setAttribute("id", "gtm");

      gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
          j.setAttribute('class','optanon-category-C0001');j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T6SM3VW');
      `;

      document.head.appendChild(gtmScript);

      return () => {
        document.head.removeChild(gtmScript);
      };
    }
  }, [isChina]);

  return null;
};

export default GoogleTagManagerScript;
