import styled from "@emotion/styled";
import {
  Caption,
  Colors,
  Section,
  Sizes,
  Spacer,
} from "@polestar/component-warehouse-react";

interface Props {
  readonly isPreview: boolean;
  readonly previewEndpoint: string;
  readonly ephemeralDeploymentId?: string;
}

const StyledWrapper = styled(Section)`
  background-color: ${Colors.pebbleGrey};
`;

const StyledTextButton = styled.a`
  text-decoration: underline;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
`;

const DeveloperMetadata = ({
  isPreview,
  previewEndpoint,
  ephemeralDeploymentId,
}: Props) => {
  const message = isPreview ? (
    <>
      You are viewing this page in preview mode. This means you are seeing saved
      instead of published data from Dato.&nbsp;
      <StyledTextButton
        data-testid="preview-status-exit"
        aria-label="Exit preview mode"
        href={previewEndpoint}
      >
        Click here to exit preview mode.
      </StyledTextButton>
    </>
  ) : (
    <>
      You are viewing this page in published mode. This means you are seeing
      published data from Dato.&nbsp;
      <StyledTextButton
        data-testid="preview-status-enter"
        aria-label="Enter preview mode"
        href={previewEndpoint}
      >
        Click here to enter preview mode.
      </StyledTextButton>
    </>
  );

  return (
    <StyledWrapper as="div" data-testid="developer-metadata">
      <Caption size={Sizes.small}>{message}</Caption>
      <Spacer />
      {ephemeralDeploymentId && (
        <>
          <Caption size={Sizes.small}>
            Ephemeral Deployment ID: {ephemeralDeploymentId}
          </Caption>
          <Spacer />
        </>
      )}
    </StyledWrapper>
  );
};

export default DeveloperMetadata;
