import styled from "@emotion/styled";
import {
  Heading,
  Spacings,
  MediaQueries,
  SecondaryAnchorButton,
} from "@polestar/component-warehouse-react";

const StyledHeading = styled(Heading)`
  margin-top: ${Spacings.xLarge};
  margin-bottom: ${Spacings.medium};
  word-break: "keep-all";

  ${MediaQueries.desktop} {
    margin-top: 0;
  }
`;

interface Props {
  readonly title: string;
  readonly buttonLabel: string;
  readonly buttonUrl: string;
  readonly redirectUrl: string;
}

const NewsletterBanner = ({
  title,
  buttonLabel,
  buttonUrl,
  redirectUrl,
}: Props) => {
  return (
    <>
      <StyledHeading level={2}>{title}</StyledHeading>
      <SecondaryAnchorButton
        monochrome
        href={`${buttonUrl}?redirect-url=${redirectUrl}`}
      >
        {buttonLabel}
      </SecondaryAnchorButton>
    </>
  );
};

export default NewsletterBanner;
