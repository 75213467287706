import { getMarkets } from "@dotcom/markets";
import { getAlternateLinks, getPageMeta, mergeMeta } from "@dotcom/remix-meta";
import {
  createCustomSpacing,
  Spacer,
  Spacings,
} from "@polestar/component-warehouse-react";
import { json, type LoaderFunctionArgs } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";

import { Topics } from "./components/Topics";
import getLandingPageData from "./data/getLandingPageData";

import { TextHero } from "~/common/components/TextHero";
import { getErrorPageData } from "~/common/data";
import { getIsChina } from "~/common/helpers/.server";
import { type RootLoader } from "~/root";

export const meta = mergeMeta<typeof loader, { root: RootLoader }>(
  ({ data, location }) => {
    if (!data) return [];

    const { seoMetaTag, hasNoRobotMeta, publishedMarkets } =
      data.landingPageData;

    return [
      ...getPageMeta({
        title: seoMetaTag?.title,
        description: seoMetaTag?.description,
        image: seoMetaTag?.image?.url,
        twitterCard: seoMetaTag?.twitterCard,
        noIndex: hasNoRobotMeta,
      }),
      ...getAlternateLinks(location.pathname, publishedMarkets, data.isChina),
    ];
  }
);

export const loader = async ({
  context: { market, datoClient },
  request,
}: LoaderFunctionArgs) => {
  if (!market) {
    throw json(
      await getErrorPageData(datoClient, { requestUrl: request.url }),
      { status: 404 }
    );
  }

  const landingPageData = await getLandingPageData(datoClient);

  if (!landingPageData) {
    throw json(
      await getErrorPageData(datoClient, { market, requestUrl: request.url }),
      { status: 404 }
    );
  }

  const isChina = getIsChina();
  return {
    landingPageData,
    market,
    markets: getMarkets(datoClient.isPreview, isChina),
    isChina,
  };
};

const LandingPage = () => {
  const {
    landingPageData: { heroTitle, heroSubtitle, heroBody, topics, topicsTitle },
    market,
  } = useLoaderData<typeof loader>();

  return (
    <>
      <TextHero
        title={heroTitle ?? undefined}
        subtitle={heroSubtitle ?? undefined}
        body={heroBody ?? undefined}
      />
      <Spacer
        spacing={{
          mobile: createCustomSpacing(5.6875),
          tablet: createCustomSpacing(4.5),
          desktop: Spacings.xxxxLarge,
        }}
      />

      <Topics
        title={topicsTitle ?? undefined}
        topics={topics ?? undefined}
        locale={market.locale ?? undefined}
      />
      <Spacer
        spacing={{
          mobile: Spacings.xxxLarge,
          tablet: createCustomSpacing(4.5),
          desktop: Spacings.xxxxLarge,
        }}
      />
    </>
  );
};

export default LandingPage;
